import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
} from 'react';

import { checkUser } from '../../context/api/nekosdk';
import { STORAGE_KEY, useDispatch, useTrackedState } from '../../context/store';

import Dashboard from '../Dashboard';

import { GlobalStyle, StyledSection } from './styled';

interface Props {
  location: Location;
}

const noDashboardPages = ['/burn/'];

const Layout: FC<PropsWithChildren<Props>> = ({ children, location }) => {
  const dispatch = useDispatch();
  const { isWallet } = useTrackedState();

  useLayoutEffect(() => {
    const query = new URLSearchParams(location.search);
    const walletSession = JSON.parse(
      window.sessionStorage.getItem(STORAGE_KEY) || '{}',
    );

    if (query.get('origin') === 'wallet' || walletSession?.isWallet) {
      dispatch({ type: 'SET_IS_WALLET', payload: true });
    } else if (isWallet === undefined) {
      dispatch({ type: 'SET_IS_WALLET', payload: false });
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== '/auth/') {
      // Reset loginFrom since it only needs to be used by Auth page once
      dispatch({ type: 'SET_LOGIN_FROM' });
    }
  }, []);

  useLayoutEffect(() => {
    checkUser(dispatch);
  }, [location.pathname]);

  if (noDashboardPages.includes(location.pathname)) {
    return <>{children}</>;
  }

  return (
    <>
      <GlobalStyle />
      <StyledSection>
        <Dashboard page={location.pathname}>{children}</Dashboard>
      </StyledSection>
    </>
  );
};

export default Layout;
