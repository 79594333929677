import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getAllLiquidityPairs } from '@context/api/allLiquidityPairs';
import {
  ETH_TESTNET_PROVIDERS,
  VERSE_ETH_PAIR_ADDRESS,
} from '@context/constants';
import { useTrackedState } from '@context/store';

import { findProvider } from '@helpers/utils';

import { getMatchingFarm } from '@views/Farms/helpers/getMatchingFarm';

import testnetPairs from '../../mocks/bitcoincom_seth_pairs.json';

const LiquidityPairsContext = createContext<ILiquidityPair[] | undefined>(
  undefined,
);

const LiquidityPairsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [liquidityPairs, setLiquidityPairs] = useState<ILiquidityPair[]>();
  const { provider: exchangeProvider } = useTrackedState();

  useEffect(() => {
    const fetchLiquidityPairs = async () => {
      setLiquidityPairs(undefined);
      const provider = findProvider(exchangeProvider);

      if (!provider.isDeFi || !provider.subgraphUrl) return;

      const response = ETH_TESTNET_PROVIDERS.includes(exchangeProvider)
        ? testnetPairs
        : await getAllLiquidityPairs(provider.subgraphUrl, exchangeProvider);

      if (!response) return;

      const sortedByLiquidity = response.sort((pair1, pair2) => {
        if (parseFloat(pair1.reserveUSD) < parseFloat(pair2.reserveUSD))
          return 1;
        if (parseFloat(pair1.reserveUSD) > parseFloat(pair2.reserveUSD))
          return -1;
        return 0;
      });

      const sortedPairs = sortedByLiquidity
        .sort((a, b) => {
          const matchingFarm1 = getMatchingFarm(a.id, exchangeProvider);
          const matchingFarm2 = getMatchingFarm(b.id, exchangeProvider);

          if (matchingFarm1 && !matchingFarm2) return -1;
          if (!matchingFarm1 && matchingFarm1) return 1;
          return 0;
        })
        .sort((a, b) => {
          const isDefaultFarmA = a.id === VERSE_ETH_PAIR_ADDRESS.toLowerCase();
          const isDefaultFarmB = b.id === VERSE_ETH_PAIR_ADDRESS.toLowerCase();

          if (isDefaultFarmA) return -1;
          if (isDefaultFarmB) return 1;
          return 0;
        });

      setLiquidityPairs(sortedPairs);
    };
    fetchLiquidityPairs();
  }, [exchangeProvider]);

  return (
    <LiquidityPairsContext.Provider value={liquidityPairs}>
      {children}
    </LiquidityPairsContext.Provider>
  );
};

export const useLiquidityPairs = () => useContext(LiquidityPairsContext);

export default LiquidityPairsProvider;
