import React from 'react';

import Intl from '@i18n/index';
import { GatsbyBrowser } from 'gatsby';
import { DefaultTheme, ThemeProvider } from 'styled-components';

import theme from '@bitcoin-portal/verse-web-components/dist/themes/verse';

import Layout from '@components/Layout';

import EnsProvider from '@context/providers/EnsProvider';
import ExchangeRatesProvider from '@context/providers/ExchangeRatesProvider';
import { FarmsProvider } from '@context/providers/FarmsProvider';
// import FarmsApyProvider from '@context/providers/FarmsApyProvider';
import LiquidityPairsProvider from '@context/providers/LiquidityPairsProvider';

import { CexTokensProvider } from '@views/Swap/context/providers/CexTokensProvider';
import { LiFiProvider } from '@views/Swap/context/providers/LiFiProvider';
import MultichainBalancesProvider from '@views/Swap/context/providers/MultichainBalancesProvider';
import { MultichainSwapProvider } from '@views/Swap/context/providers/MultichainSwapProvider';
import MultichainTokensProvider from '@views/Swap/context/providers/MultichainTokensProvider';
import { SwapProvider } from '@views/Swap/context/providers/SwapProvider';

import BcomAccountsProvider from './providers/BcomAccountsProvider';
import DexRatesProvider from './providers/DexRatesProvider';
import DexTokensProvider from './providers/DexTokensProvider';
import VerseBalancesProvider from './providers/VerseBalancesProvider';
import VerseClaimMerkleProvider from './providers/VerseClaimMerkleProvider';
import { Provider } from './store';

const independentPages = ['/card/'];

const WrapPageElement: GatsbyBrowser<
  null,
  { locale: string }
>['wrapPageElement'] = ({ props, element }) => {
  if (independentPages.some(p => props.location.pathname.startsWith(p))) {
    return (
      <Intl
        locale={props.pageContext.locale}
        localeDir={props.location.pathname}
      >
        <ThemeProvider theme={theme as DefaultTheme}>{element}</ThemeProvider>
      </Intl>
    );
  }

  return (
    <BcomAccountsProvider>
      <VerseBalancesProvider>
        <VerseClaimMerkleProvider>
          <EnsProvider>
            <SwapProvider>
              <LiFiProvider>
                <MultichainTokensProvider>
                  <MultichainBalancesProvider>
                    <Intl
                      locale={props.pageContext.locale}
                      localeDir={props.location.pathname}
                    >
                      <MultichainSwapProvider>
                        <ThemeProvider theme={theme as DefaultTheme}>
                          <Provider>
                            <LiquidityPairsProvider>
                              <DexRatesProvider>
                                <DexTokensProvider>
                                  <CexTokensProvider>
                                    <ExchangeRatesProvider>
                                      <FarmsProvider>
                                        <Layout location={props.location}>
                                          {element}
                                        </Layout>
                                      </FarmsProvider>
                                    </ExchangeRatesProvider>
                                  </CexTokensProvider>
                                </DexTokensProvider>
                              </DexRatesProvider>
                            </LiquidityPairsProvider>
                          </Provider>
                        </ThemeProvider>
                      </MultichainSwapProvider>
                    </Intl>
                  </MultichainBalancesProvider>
                </MultichainTokensProvider>
              </LiFiProvider>
            </SwapProvider>
          </EnsProvider>
        </VerseClaimMerkleProvider>
      </VerseBalancesProvider>
    </BcomAccountsProvider>
  );
};

export default WrapPageElement;
