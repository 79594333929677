import styled, { css } from 'styled-components';

import { media } from '@bitcoin-portal/verse-web-components/dist/themes/breakpoints';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

export const MenuButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.unit}px;
    width: 100%;
    cursor: pointer;
    transition: background 0.2s ease-out;
    color: ${theme.palette.text.default};
    font-size: 16px;
    font-weight: 600;
    padding: ${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 3}px;
    border: none;
    outline: none;
    background: none;
    &:hover {
      background: ${colors.verseDarkBlue};
    }
    ${media.sm`
      font-size: 14px;
      padding:
        ${theme.spacing.unit}px
        ${theme.spacing.unit * 2}px;
    `}
  `}
`;

export const SwapIconWrapper = styled.span<{
  large?: boolean;
}>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${colors.primaryBlue100};
  & > svg {
    height: 18px;
  }
  ${props =>
    props.large &&
    css`
      width: 24px;
      height: 24px;
      & > svg {
        height: 24px;
      }
    `}
`;
