import React, { FunctionComponent } from 'react';

import { useLocation } from '@reach/router';

import { useTrackedState } from '../../../context/store';

import LoginButton from '../../LoginButton';
import ProviderMenu from '../../ProviderMenu';
import {
  HeaderBtnWrapper,
  HeaderSection,
  StyledHeaderIcon,
  StyledHeaderLogo,
} from './styled';

const Header: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { isWallet } = useTrackedState();

  return (
    <HeaderSection hasBottomBorder={pathname === '/'} offsetTop={false}>
      <div>
        <StyledHeaderIcon
          height={36}
          width={36}
          src="/icons/verse-icon-512x512.png"
          alt="Verse DEX"
        />
        <StyledHeaderLogo
          height="auto"
          width={117}
          src="/images/uploads/versev3-logo.png"
          alt="Verse DEX"
        />
      </div>
      <HeaderBtnWrapper>
        {isWallet === false && <ProviderMenu />}
        <LoginButton />
      </HeaderBtnWrapper>
    </HeaderSection>
  );
};

export default Header;
