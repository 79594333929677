import { useMemo } from 'react';

import { useLocation } from '@reach/router';

import { MULTICHAIN_DETAILS } from '../context/constants';

import { isFeatureFlagEnabled } from '../helpers';

export const useMultichainProvider = (
  prov: IProviderOptionType,
): { icon: string; label: string } => {
  const { pathname } = useLocation();

  const multichainSwapEnabled = isFeatureFlagEnabled('multichain');

  const showMultichain = useMemo(
    () =>
      multichainSwapEnabled &&
      prov.provider === 'bitcoincom_eth' &&
      pathname.includes('/swap'),
    [prov.provider, pathname],
  );

  const icon = useMemo(
    () => (showMultichain ? MULTICHAIN_DETAILS.icon : prov.icon) || '',
    [showMultichain, prov.provider],
  );

  const label = useMemo(
    () => (showMultichain ? MULTICHAIN_DETAILS.label : prov.label),
    [showMultichain, prov.provider],
  );

  return { icon, label };
};

export default useMultichainProvider;
