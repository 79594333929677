import React, { FunctionComponent, useEffect, useState } from 'react';

import { JsonRpcProvider } from '@ethersproject/providers';
import { useEthers } from '@usedapp/core';

import ChevronDown from '@bitcoin-portal/verse-web-components/dist/Icons/ChevronDown';
import Swap from '@bitcoin-portal/verse-web-components/dist/Icons/Swap';

import { ACTIVE_ENV } from '@context/constants';
import { useDispatch, useTrackedState } from '@context/store';
import { TExchangeProvider } from '@context/types';

import { useSwapContext } from '@views/Swap/context/providers/SwapProvider';

import { lookupCexProvider, useQuery } from '@helpers/index';

import { useMultichainProvider } from '@hooks/useMultichainProvider';

import Image from '../Image';
import ProviderSection from './ProviderSection';
import {
  MenuWrapper,
  ProviderButton,
  ProviderList,
  ProviderModalMenu,
  SwapIconWrapper,
} from './styled';

const providerOptions: IProviderOptionType[] = [
  {
    label: 'Ethereum',
    provider: 'bitcoincom_eth',
    chain: 1,
    icon: '/images/uploads/eth.png',
    isDeFi: true,
  },
  {
    label: 'SmartBCH',
    provider: 'bitcoincom',
    chain: 10000,
    icon: '/images/uploads/smartbch.png',
    isDeFi: true,
  },
  {
    label: 'Centralized',
    provider: 'sideshift',
    icon: null,
  },
  {
    label: 'Centralized',
    provider: 'changenow',
    icon: null,
  },
  {
    label: 'Sepolia Testnet',
    provider: 'bitcoincom_seth',
    chain: 11155111,
    icon: '/images/uploads/sepolia.png',
    isTestnet: true,
  },
  {
    label: 'Holesky Testnet',
    provider: 'bitcoincom_heth',
    chain: 17000,
    icon: '/images/uploads/holesky.png',
    isTestnet: true,
  },
];

const ProviderMenu: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { provider } = useTrackedState();
  const { library, deactivate, switchNetwork } = useEthers();
  const { cexProvider: cexKey } = useSwapContext();

  const [providerMenuOpen, setProviderMenuOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(0);
  const [cexProvider, setCexProvider] = useState('sideshift');

  const selectedProviderOption = providerOptions[selectedProvider];

  const { icon, label } = useMultichainProvider(selectedProviderOption);

  const testnetEnabled = ['development', 'staging'].includes(ACTIVE_ENV);

  const query = useQuery();
  useEffect(() => {
    const exchangeProvider = query.get('provider') || provider;

    const providerIdx = providerOptions
      .map(o => o.provider)
      .indexOf(exchangeProvider);

    if (exchangeProvider && providerIdx >= 0) {
      setSelectedProvider(providerIdx);
    }
  }, [provider, query]);

  useEffect(() => {
    if (cexKey) setCexProvider(lookupCexProvider(cexKey));
  }, [cexKey]);

  const onProviderChange = (newProvider: string) => {
    const isMetaMask =
      (library as JsonRpcProvider)?.connection?.url === 'metamask';

    if (isMetaMask) return;

    if (newProvider === 'bitcoincom') {
      deactivate();
    }
  };

  const onProviderSelect = async (prov: IProviderOptionType) => {
    try {
      const { provider: selected } = prov;

      if ((prov.isDeFi || prov.isTestnet) && prov.chain) {
        await switchNetwork(prov.chain);
      }

      dispatch({
        type: 'SET_PROVIDER',
        payload: selected as TExchangeProvider,
      });
      if (onProviderChange) onProviderChange(selected);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ProviderModalMenu
      open={providerMenuOpen}
      setOpen={setProviderMenuOpen}
      width={200}
      menuItem={
        <ProviderButton
          design="secondary"
          small
          onClick={() => {
            setProviderMenuOpen(!providerMenuOpen);
          }}
          style={{ gap: '8px' }}
        >
          {selectedProviderOption.icon ? (
            <Image src={icon} width={24} height={24} alt="provider icon" />
          ) : (
            <SwapIconWrapper large>
              <Swap size={16} />
            </SwapIconWrapper>
          )}
          <span>{label}</span>
          <ChevronDown size={12} />
        </ProviderButton>
      }
    >
      <MenuWrapper>
        <ProviderList>
          <ProviderSection
            label="Decentralized Exchanges"
            options={providerOptions.filter(prov => !!prov.isDeFi)}
            isCex={false}
            onProviderSelect={onProviderSelect}
          />
          <ProviderSection
            label="Centralized Exchanges"
            options={providerOptions.filter(
              prov => !prov.isDeFi && prov.provider === cexProvider,
            )}
            isCex
            onProviderSelect={onProviderSelect}
          />
          {testnetEnabled && (
            <ProviderSection
              label="Test Exchanges"
              options={providerOptions.filter(prov => prov.isTestnet)}
              isCex={false}
              onProviderSelect={onProviderSelect}
            />
          )}
        </ProviderList>
      </MenuWrapper>
    </ProviderModalMenu>
  );
};

export default ProviderMenu;
