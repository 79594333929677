import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const HomeIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="home" viewBox="0 0 21 20" fill="none" {...rest}>
        <path
          d="M10.7282 0L0.666504 9.9994V13.633L10.7017 3.65256L20.6665 13.6723V10.0006L10.7282 0Z"
          fill="currentColor"
        />
        <path
          d="M10.7157 6.36769L17.7915 13.533V19.9026H13.2231V12.7463H7.98497V19.9026H3.4165V13.5768L10.7157 6.36769Z"
          fill="currentColor"
        />
      </SvgIcon>
    </>
  );
};

export default HomeIcon;
