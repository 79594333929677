/* eslint-disable max-lines */

/* eslint-disable no-underscore-dangle */
import { Dispatch, SetStateAction } from 'react';

import { formatUnits } from '@ethersproject/units';
import { BigNumber } from 'ethers/lib/ethers';
import { toChecksumAddress } from 'web3-utils';

import coinList from '@bitcoin-portal/verse-web-components/dist/Coin/coinList';

import {
  DEFAULT_DECIMALS,
  DEFAULT_VALUE,
  PRECISION,
  PROVIDER_ICON_URLS,
} from '@context/constants';
import { providers } from '@context/tokens';

import { formatValue } from '@helpers/formatValue';
import { getCustomDecimals, isDeFiProvider } from '@helpers/index';

export const useQuery = (): URLSearchParams => {
  if (typeof window !== 'undefined')
    return new URLSearchParams(window.location.search);

  return new URLSearchParams();
};

export const getStorage = (key: string): string | null => {
  if (typeof window !== 'undefined') return localStorage.getItem(key);
  return '';
};

export const setStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

export const lookupCexProvider = (key: string): string => {
  switch (key) {
    case 'SIDESHIFT_CEX_PROVIDER':
      return 'sideshift';
    case 'CHANGE_NOW_CEX_PROVIDER':
      return 'changenow';
    default:
      return 'sideshift';
  }
};

export const shortAddress = (
  address: string | null | undefined,
  size = 5,
): string => {
  if (!address) return '';
  if (typeof address !== 'string') return '';

  const short = window.innerWidth / window.devicePixelRatio < 750;
  const { length } = address;
  if (length < size * 2) return address;
  const display =
    address && short
      ? [
          ...address.split('').slice(0, size),
          '...',
          ...address.split('').slice(length - size),
        ].join('')
      : [
          ...address.split('').slice(0, size),
          '...',
          ...address.split('').slice(length - size),
        ].join('');

  return display === '...' ? '' : display;
};

export const validateEmail = (email: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const getReadableValue = (val?: BigNumber): number | undefined => {
  if (typeof val === 'undefined') return undefined;

  return parseFloat(formatValue(val, DEFAULT_DECIMALS));
};

// for pagination
export const pairsSplitter = (
  pageSize: number,
  array: unknown[],
  pagedArray: unknown[][] = [],
): unknown[][] =>
  array.length === 0
    ? pagedArray
    : pairsSplitter(
        pageSize,
        array.slice(pageSize),
        pagedArray.concat([array.slice(0, pageSize)]),
      );

/* --- FORMATTERS --- */
export const sanitizeInput = (str: string): number => {
  return parseFloat((str.match(/\d|\./g) || []).join(''));
};

export const addThousandsSeparator = (n: string): string => {
  const parts = n.split('.');
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replaceAll(thousands, ',') +
    (decimalPart ? `.${decimalPart}` : '')
  );
};

export const toFixed = (
  num: number,
  fixed: number,
  noThousandSeparator?: boolean,
): string => {
  if (Math.abs(num) > 0) {
    const fixedNum = num.toFixed(fixed);
    const formattedNum = noThousandSeparator
      ? fixedNum
      : addThousandsSeparator(fixedNum);
    return formattedNum;
  }

  return '0.00';
};

export const toLocaleString = (
  value: string | number,
  digits: number,
): string => {
  if (!value && value !== 0) return '';
  return parseFloat(value.toString()).toLocaleString('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

/* --- FINDERS --- */
export const findProvider = (name: string | undefined): IProviderObject => {
  return (
    providers.find((x: IProviderObject) => x.name === name) || providers[0]
  );
};

export const providerFromPath = (path: string): IProviderObject => {
  const provider =
    providers.find((x: IProviderObject) => x.chainParam === path) ||
    providers[0];
  return provider;
};

export const findOption = (
  array: ITickerObject[],
  value: string | null,
  index: number,
  protocol?: string,
  blockchain?: string,
): ITickerObject => {
  const res =
    array.find((x: ITickerObject) => {
      const matchProtocol = protocol ? x.protocol === protocol : true;
      const matchBlockchain = blockchain ? x.blockchain === blockchain : true;
      return matchProtocol && matchBlockchain && x.value === value;
    }) || array[index];

  return res;
};

export const findTicker = (array: ITickerObject[], value: string): string => {
  const res = array.find((x: ITickerObject) => x.value === value);
  return res?.ticker || array[0]?.ticker;
};

export const findTokenStrict = (
  array: ITickerObject[],
  value: string,
  defaultValue: string,
): string => {
  const res = array.find((x: ITickerObject) => x.value === value);
  return res?.token || defaultValue;
};

export const findExternalIcon = (
  tokenId: string | undefined,
  symbol: string,
  provider?: string,
): string | undefined => {
  if (!tokenId || Object.keys(coinList).includes(symbol.toLowerCase()))
    return undefined;
  const checksumAddress = toChecksumAddress(tokenId);
  const providerBase =
    provider && isDeFiProvider(provider) ? provider : 'bitcoincom_eth';
  const iconBaseUrl = PROVIDER_ICON_URLS[providerBase];

  return `${iconBaseUrl}/${checksumAddress}/logo.png`;
};

/* --- SORTERS ---  */
export const sortPoolsByLiquidity = (
  array: ILiquidityPair[],
): ILiquidityPair[] => {
  return array?.sort((pair1, pair2) => {
    if (parseFloat(pair1.reserveUSD) < parseFloat(pair2.reserveUSD)) return 1;
    if (parseFloat(pair1.reserveUSD) > parseFloat(pair2.reserveUSD)) return -1;
    return 0;
  });
};

/* --- RATES --- (move somewhere else?) */

export const convertToGasEther = (
  gasUnits: BigNumber | undefined,
  wei: string,
): number => {
  const transactionFee = gasUnits?.mul(parseInt(wei, 10));

  return transactionFee ? parseFloat(formatUnits(transactionFee, 18)) : 0;
};

export const convertFromTo = (
  newFromValue: string,
  rate: number,
  setToValue?: Dispatch<SetStateAction<string>>,
  checkLimits?: (val: string) => boolean,
): void => {
  const newToValue = sanitizeInput(newFromValue || DEFAULT_VALUE) * rate;
  const customDecimals = getCustomDecimals(newToValue, PRECISION);

  if (setToValue) {
    setToValue(newToValue.toFixed(customDecimals));
  }

  if (checkLimits) {
    checkLimits(newFromValue);
  }
};

export const convertToFrom = (
  newToValue: string,
  rate: number,
  setFromValue?: Dispatch<SetStateAction<string>>,
  checkLimits?: (val: string) => boolean,
): void => {
  const newFromValue = sanitizeInput(newToValue || DEFAULT_VALUE) / rate;
  const customDecimals = getCustomDecimals(newFromValue, PRECISION);

  if (setFromValue) {
    setFromValue(newFromValue.toFixed(customDecimals));
  }

  if (checkLimits) {
    checkLimits(newFromValue.toFixed(customDecimals));
  }
};
