import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Token } from '@lifi/sdk';
import { useEthers } from '@usedapp/core';

import { LIFI_CHAINS } from '@context/constants';
import { useBcomAccounts } from '@context/providers/BcomAccountsProvider';
import { LiFiBalancesType } from '@context/types';

import { useLiFi } from '@views/Swap/context/providers/LiFiProvider';

import { useMultichainTokens } from './MultichainTokensProvider';

type TMultichainBalancesContext = {
  tokenBalances: LiFiBalancesType;
  loading: boolean;
};

const MultichainBalancesContext = createContext<TMultichainBalancesContext>({
  tokenBalances: {},
  loading: false,
});

const MultichainBalancesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { bcomAccounts, bcomChains } = useBcomAccounts();
  const { account } = useEthers();
  const liFi = useLiFi();
  const multichainTokens = useMultichainTokens();

  const [loading, setLoading] = useState<boolean>(false);
  const [tokenBalances, setTokenBalances] = useState<LiFiBalancesType>({});

  // fetch native token balances
  useEffect(() => {
    const fetchTokenBalances = async () => {
      if (!multichainTokens || !account || !liFi) {
        setTokenBalances({});
        return;
      }

      setLoading(true);

      const tokensForChain: { [chainId: number]: Token[] } = Object.keys(
        multichainTokens,
      ).reduce((acc, chain) => {
        const { chainId } = LIFI_CHAINS[chain];
        const tokens = multichainTokens[chain].map(token => ({
          chainId: LIFI_CHAINS[chain].chainId,
          priceUSD: token?.priceUSD || '',
          symbol: token?.abbr || '',
          decimals: token?.decimals || 18,
          name: token?.label || '',
          address: token?.token || '',
        }));

        return {
          ...acc,
          [chainId]: tokens,
        };
      }, {});

      try {
        if (bcomChains) {
          const balances: LiFiBalancesType = {};
          await Promise.all(
            bcomChains.map(async (chain: number) => {
              const tokens = {
                [chain]: tokensForChain[chain],
              };

              const chainAccount = bcomAccounts[chain];

              if (chainAccount) {
                const res = await liFi.getTokenBalancesForChains(
                  chainAccount,
                  tokens,
                );

                if (res?.[chain]) {
                  balances[chain] = res[chain];
                }
              }
            }),
          );

          setLoading(false);
          setTokenBalances(balances);
        } else {
          const balances = await liFi.getTokenBalancesForChains(
            account,
            tokensForChain,
          );

          setLoading(false);
          setTokenBalances(balances);
        }
      } catch {
        setLoading(false);
      }
    };

    fetchTokenBalances();
  }, [account, bcomChains, multichainTokens]);

  const getTokenBalance = useCallback(
    (tokenAddress: string, chainId: number = 1) => {
      if (!tokenAddress || loading) return undefined;

      return tokenBalances?.[chainId]?.find(
        token => token.address?.toLowerCase() === tokenAddress?.toLowerCase(),
      );
    },
    [tokenBalances, loading],
  );

  const value: TMultichainBalancesContext = useMemo(() => {
    return {
      getTokenBalance,
      tokenBalances,
      loading,
    };
  }, [tokenBalances, loading]);

  return (
    <MultichainBalancesContext.Provider value={value}>
      {children}
    </MultichainBalancesContext.Provider>
  );
};

export const useMultichainBalances = () =>
  useContext(MultichainBalancesContext);

export default MultichainBalancesProvider;
