import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const FarmsIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="farms" viewBox="0 0 20 20" fill="none" {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0001 1.30532V0.0501519C19.7901 0.0291491 19.5782 0.0146175 19.3646 0.00675918C19.1808 0 18.9635 0 18.5288 0C18.1376 0 17.942 0 17.7766 0.00608313C14.6757 0.120153 11.9785 1.79654 10.4478 4.27264C11.8967 6.06339 12.7945 8.31895 12.8852 10.7831C12.8899 10.9108 12.8916 11.0492 12.8923 11.221C13.9133 11.1817 14.6362 11.0892 15.271 10.8715C17.3149 10.1705 18.9205 8.56489 19.6214 6.52103C20.0001 5.41711 20.0001 4.04652 20.0001 1.30532ZM11.4584 15.4356C10.5705 15.4376 9.6826 15.4394 8.79469 15.4374C8.7313 16.8431 7.60335 17.9718 6.19859 18.1892C4.0805 18.5171 1.99566 19.1207 3.05176e-05 20H20C18.0873 19.1572 16.0926 18.5677 14.0652 18.2315C12.6507 17.9969 11.5205 16.8541 11.4584 15.4356ZM0 3.49281V2.23765C0.209973 2.21665 0.421873 2.20212 0.635498 2.19426C0.819241 2.1875 1.03658 2.1875 1.47127 2.1875C1.86248 2.1875 2.05809 2.1875 2.22346 2.19358C6.93429 2.36687 10.7132 6.14579 10.8865 10.8566C10.8926 11.022 10.8926 11.2176 10.8926 11.6088V13.4368C10.5959 13.4375 10.2806 13.4375 9.94475 13.4375C7.20356 13.4375 5.83296 13.4375 4.72905 13.0589C2.68519 12.3579 1.0796 10.7523 0.378612 8.70848C0 7.60458 0 6.23399 0 3.49281Z"
          fill="currentColor"
        />
      </SvgIcon>
    </>
  );
};

export default FarmsIcon;
