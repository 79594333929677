import styled, { css } from 'styled-components';

import Button from '@bitcoin-portal/verse-web-components/dist/Button';
import ModalMenu from '@bitcoin-portal/verse-web-components/dist/ModalMenu';
import { media } from '@bitcoin-portal/verse-web-components/dist/themes/breakpoints';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

export const ProviderButton = styled(Button)`
  border-radius: 18px;
  height: 36px;
  padding: 0 8px;
  & > span {
    line-height: 10px;
    &:nth-child(2) {
      display: none;
    }
  }
  ${media.sm`
    & > span:nth-child(2) {
      display: inline;
    }
  `}
`;

export const ProviderModalMenu = styled(ModalMenu)`
  .vs-modal-menu {
    position: fixed;
    top: 56px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    ${media.sm`
      position: absolute;
      top: 48px;
      left: unset;
      width: 200px;
      height: fit-content;
      border-radius: 12px;
    `}
  }
`;
export const MenuWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: calc(100% - 72px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${media.sm`
      display: block;
      height: unset;
      padding: ${theme.spacing.unit}px 0px;
    `}
  `}
`;

export const ProviderList = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.unit * 2}px;
    ${media.sm`
      padding-top: 0px;
    `}
  `}
`;

export const SwapIconWrapper = styled.span<{
  large?: boolean;
}>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${colors.primaryBlue100};
  & > svg {
    height: 18px;
  }
  ${props =>
    props.large &&
    css`
      width: 24px;
      height: 24px;
      & > svg {
        height: 24px;
      }
    `}
`;
