import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const HelpIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="help" viewBox="0 0 20 20" fill="none" {...rest}>
        <path
          d="M2.33312 16.4206C0.876843 14.6834 0 12.4441 0 10C0 7.81182 0.702815 5.7878 1.89502 4.14134L4.78481 7.03113C4.28537 7.90658 4 8.91996 4 10C4 11.339 4.43859 12.5755 5.17994 13.5737L2.33312 16.4206Z"
          fill="#899BB5"
        />
        <path
          d="M7.52772 15.4686L4.58675 18.4096C6.14698 19.416 8.00528 20 10 20C12.1882 20 14.2122 19.2972 15.8587 18.105L12.9689 15.2152C12.0934 15.7146 11.08 16 10 16C9.1186 16 8.2816 15.81 7.52772 15.4686Z"
          fill="#899BB5"
        />
        <path
          d="M15.4686 7.52772L18.4096 4.58675C19.416 6.14698 20 8.00528 20 10C20 12.2523 19.2554 14.3308 17.9988 16.0025L15.1223 13.126C15.6791 12.2156 16 11.1453 16 10C16 9.1186 15.81 8.2816 15.4686 7.52772Z"
          fill="#899BB5"
        />
        <path
          d="M16.4206 2.33312L13.5737 5.17994C12.5755 4.43859 11.339 4 10 4C8.85471 4 7.78437 4.32089 6.87398 4.87766L3.99748 2.00116C5.66923 0.744637 7.74766 0 10 0C12.4441 0 14.6834 0.876843 16.4206 2.33312Z"
          fill="#899BB5"
        />
      </SvgIcon>
    </>
  );
};

export default HelpIcon;
