import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type MultichainSwapState = {
  isMultichainSwap: boolean;
  setIsMultichainSwap: Dispatch<SetStateAction<boolean>>;
};

export const INITIAL_STATE = {
  isMultichainSwap: false,
  setIsMultichainSwap: () => false,
};

const MultichainSwapContext = createContext<MultichainSwapState>(INITIAL_STATE);

export const MultichainSwapProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isMultichainSwap, setIsMultichainSwap] = useState(false);

  const contextValue = useMemo(
    () => ({
      isMultichainSwap,
      setIsMultichainSwap,
    }),
    [isMultichainSwap],
  );

  return (
    <MultichainSwapContext.Provider value={contextValue}>
      {children}
    </MultichainSwapContext.Provider>
  );
};

export const useMultichainSwapContext = () => useContext(MultichainSwapContext);
