import { Token } from '@lifi/sdk';

import {
  LIFI_BLACK_LIST,
  LIFI_PRICE_FLOOR,
  VERSE_TOKENS,
} from '@views/Swap/context/constants';

export const filterMultichainTokens = (chainTokens: Token[]) => {
  return chainTokens
    .filter(
      token =>
        (!token.priceUSD.includes('e') &&
          token.priceUSD !== '0' &&
          parseFloat(token.priceUSD) >= LIFI_PRICE_FLOOR &&
          token.logoURI) ||
        Object.values(VERSE_TOKENS).includes(token.address.toLowerCase()),
    )
    .filter(token => !LIFI_BLACK_LIST.includes(token.address));
};
