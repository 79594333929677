/* eslint-disable max-lines */
import {
  JsonRpcProvider,
  JsonRpcSigner,
  Log,
  TransactionReceipt,
} from '@ethersproject/providers';
import { BigNumber, BigNumberish, ethers } from 'ethers';

import { Erc20Controller } from '@bitcoin-portal/neko-web-sdk';

import {
  DEFAULT_CHAIN,
  DEFAULT_DECIMALS,
  ETH_TRANSFER_SIG,
  PRECISION,
  SUPPORTED_CHAIN_LIST,
} from '@context/constants';
import { providers } from '@context/tokens';

import { APPROVE_DEFAULT_VALUE } from '@views/Pools/context/constants';

import { formatValue } from '@helpers/formatValue';

import { prepareMin } from '@views/Pools/helpers/prepareMin';

import { ERC20ABI } from '../contracts/erc20ABI';
import { StakingABI } from '../contracts/stakingABI';

import { isProvidersNativeToken } from './index';

// interfaces
export const ERC20Interface = new ethers.utils.Interface(ERC20ABI);
export const StakingInterface = new ethers.utils.Interface(StakingABI);

// chain
export const chainIdToExplorer = (chainId?: number): string => {
  switch (chainId) {
    case 1:
      return 'etherscan.io';
    case 3:
      return 'ropsten.etherscan.io';
    case 43114:
      return 'snowtrace.io';
    case 56:
      return 'bscscan.com';
    case 137:
      return 'polygonscan.com';
    case 10000:
      return 'smartscout.cash';
    case 10001:
      return 'smartscout.cash';
    case 11155111:
      return 'sepolia.etherscan.io';
    default:
      return 'etherscan.io';
  }
};

export const supportedChain = (chainId: number): boolean => {
  return SUPPORTED_CHAIN_LIST.includes(chainId);
};

export const chainDetails = (
  provider: IProviderObject,
  chainId = DEFAULT_CHAIN,
): IChainObject => {
  return (
    provider.supportedChains.find((c: IChainObject) => c.chainId === chainId) ||
    provider.supportedChains[0]
  );
};

// provider
export const isDeFiProvider = (name: string): boolean => {
  return (
    providers.find((x: IProviderObject) => x.name === name)?.isDeFi || false
  );
};
export const isMetaMask = (library?: JsonRpcProvider): boolean => {
  if (!library) return false;
  return library.connection.url === 'metamask';
};

export const isWalletConnect = (library?: JsonRpcProvider): boolean => {
  if (!library) return false;
  return library.connection.url === 'eip-1193:';
};

// contracts
export const getContractDecimals = async (
  token: string,
  signer?: JsonRpcSigner,
): Promise<number> => {
  if (!signer) return 18;

  try {
    const tokenContract = new Erc20Controller(signer, token);
    const res = await tokenContract.getDecimals();

    if (res.isFailure) return 18;
    const decimals = res.getValue();

    return decimals || 18;
  } catch {
    return 18;
  }
};

// defi
export const getTransferLogs = async (
  txReceipt: TransactionReceipt,
): Promise<Log[]> => {
  const transferLogs = txReceipt.logs?.filter(item => {
    const isTransferTx = item.topics[0] === ETH_TRANSFER_SIG;
    return isTransferTx;
  });
  return transferLogs;
};

export const subgraphApiForProvider = (provider: IProviderObject): string => {
  if (provider?.name === 'bitcoincom_eth')
    return 'https://subgraph.api.bitcoin.com/subgraphs/name/verse/exchange';

  return provider?.subgraphUrl || '';
};

// TODO: might no longer need helpers below after refactoring gas estimate hook
export const prepAddTokenTx = (
  input0: number,
  input1: number,
  pair: ILiquidityPair,
  readableAllowance0: BigNumberish,
  readableAllowance1: BigNumberish,
): prepAddTxReturn => {
  const decimal0 = parseInt(pair.token0?.decimals, 10);
  const decimal1 = parseInt(pair.token1?.decimals, 10);

  const amountADesired = ethers.utils.parseUnits(
    input0.toFixed(PRECISION),
    decimal0,
  );
  const amountBDesired = ethers.utils.parseUnits(
    input1.toFixed(PRECISION),
    decimal1,
  );

  const amountAMin = prepareMin(input0, decimal0);
  const amountBMin = prepareMin(input1, decimal1);

  const needApprovalA = amountADesired.gt(
    readableAllowance0 || APPROVE_DEFAULT_VALUE,
  );
  const needApprovalB = amountBDesired.gt(
    readableAllowance1 || APPROVE_DEFAULT_VALUE,
  );

  return {
    amountADesired,
    amountBDesired,
    amountAMin,
    amountBMin,
    needApprovalA,
    needApprovalB,
  };
};

export const prepAddEthTx = (
  valueA: number,
  valueB: number,
  pair: ILiquidityPair,
  readableAllowance0: BigNumberish,
  readableAllowance1: BigNumberish,
  exchangeProvider: string,
): prepAddEthTxReturn => {
  const [nativeInput, tokenInput, amountTokenApproved]: [
    number,
    number,
    BigNumberish,
  ] = isProvidersNativeToken(pair.token0?.symbol, exchangeProvider)
    ? [valueA, valueB, readableAllowance1]
    : [valueB, valueA, readableAllowance0];

  const [nativeToken, token]: [ILiquidityToken, ILiquidityToken] =
    isProvidersNativeToken(pair.token0?.symbol, exchangeProvider)
      ? [pair.token0, pair.token1]
      : [pair.token1, pair.token0];

  const nativeDecimal = parseInt(nativeToken?.decimals, 10);
  const tokenDecimal = parseInt(token?.decimals, 10);

  const amountTokenDesired = ethers.utils.parseUnits(
    tokenInput.toFixed(PRECISION),
    tokenDecimal,
  );

  const amountTokenMin = prepareMin(tokenInput, tokenDecimal);
  const amountETHMin = prepareMin(nativeInput, nativeDecimal);

  const tokenAddress = token?.id;

  const sendValue = ethers.utils.parseEther(nativeInput.toString());

  const needApproval = amountTokenDesired.gt(amountTokenApproved);

  return {
    tokenAddress,
    amountTokenDesired,
    amountTokenMin,
    amountETHMin,
    sendValue,
    needApproval,
  };
};

export const prepRemoveTx = (
  pair: ILiquidityPair,
  usersLiquidity: BigNumber,
  readableBalance0: number,
  input0: number,
  input1: number,
  approvalThreshold: BigNumber,
  exchangeProvider?: string,
): prepRemoveTxReturn => {
  const needApproval = usersLiquidity.gt(approvalThreshold);

  const usersTokenAmount = Number(
    ethers.utils.formatUnits(usersLiquidity, DEFAULT_DECIMALS),
  );

  const percentageToWithdraw =
    Math.round((input0 / readableBalance0) * 100) / 100;
  const withdrawingMax = percentageToWithdraw === 1;

  const liquidityToWithdraw = withdrawingMax
    ? usersLiquidity
    : ethers.utils.parseUnits(
        (usersTokenAmount * percentageToWithdraw).toFixed(DEFAULT_DECIMALS),
        DEFAULT_DECIMALS,
      );

  // the presence of exchangeProvider means it is a native token transaction
  if (exchangeProvider) {
    const [token, nativeInput, tokenInput]: [ILiquidityToken, number, number] =
      isProvidersNativeToken(pair.token0?.symbol, exchangeProvider)
        ? [pair.token1, input1, input0]
        : [pair.token0, input0, input1];

    const tokenAddress = token?.id;
    const amountEthMin = prepareMin(nativeInput);
    const amountTokenMin = prepareMin(
      tokenInput,
      parseInt(token?.decimals, 10),
    );

    return {
      liquidityToWithdraw,
      amountAMin: amountEthMin,
      amountBMin: amountTokenMin,
      tokenAddress,
      needApproval,
    };
  }

  const amountAMin = prepareMin(input0, parseInt(pair.token0?.decimals, 10));
  const amountBMin = prepareMin(input1, parseInt(pair.token1?.decimals, 10));

  return {
    liquidityToWithdraw,
    amountAMin,
    amountBMin,
    needApproval,
  };
};

export const prepFarmWithdrawTx = (
  input: number,
  decimals: number,
  earnedAmount: number,
  stakedBalance: number,
): prepFarmWithdrawTxReturn => {
  const precision = 10 ** decimals;
  const amountTokenDesired = ethers.utils.parseUnits(
    input.toFixed(DEFAULT_DECIMALS),
    DEFAULT_DECIMALS,
  );

  const withdrawingMax =
    input === Math.floor(stakedBalance * precision) / precision;

  return {
    maxWithdrawal: withdrawingMax,
    exitingFarm: withdrawingMax && !!earnedAmount,
    amountTokenDesired,
  };
};

export const prepFarmDepositTx = (
  input: number,
  decimals: number,
  lpTokenBalance: BigNumber,
  amountTokenApproved: BigNumberish,
): prepFarmDepositTxReturn => {
  const readableLPTokenBalance = parseFloat(
    formatValue(lpTokenBalance, DEFAULT_DECIMALS),
  );
  // eslint-disable-next-line no-restricted-properties
  const precision = 10 ** decimals;
  const depositingMax =
    input === Math.floor(readableLPTokenBalance * precision) / precision;

  const amountTokenDesired = depositingMax
    ? lpTokenBalance
    : ethers.utils.parseUnits(
        input.toFixed(DEFAULT_DECIMALS),
        DEFAULT_DECIMALS,
      );

  const needApproval = amountTokenDesired.gt(amountTokenApproved);

  return {
    amountTokenDesired,
    needApproval,
  };
};

export const accountsMapForProvider = (
  web3Provider: JsonRpcProvider,
): Record<number, string> => {
  const connectedAccounts =
    web3Provider?.provider?.namespaces?.eip155?.accounts;

  return connectedAccounts?.reduce(
    (acc: Record<string, string>, account: string) => {
      const [, chainId, address] = account.split(':');
      acc[chainId] = address;
      return acc;
    },
    {},
  );
};
