import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { useDexTokens } from '@context/providers/DexTokensProvider';

import {
  DEFAULT_SWAP_FROM,
  DEFAULT_SWAP_TO,
  VERSE_TOKENS,
} from '@views/Swap/context/constants';
import { useMultichainTokens } from '@views/Swap/context/providers/MultichainTokensProvider';
import { useSwapContext } from '@views/Swap/context/providers/SwapProvider';

import { isFeatureFlagEnabled, useQuery } from '@helpers/index';

import { useTrackedState } from '../store';

// FUNCTIONS:
// updates selected token based on query params
// sets default tokens
export const DefiCurrencyHandler: FunctionComponent = () => {
  const query = useQuery();
  const multichainTokens = useMultichainTokens();

  const { provider: exchangeProvider } = useTrackedState();

  const dexTokens = useDexTokens();

  const {
    swapFromBlockchain,
    swapToBlockchain,
    swapFromCurrency,
    swapToCurrency,
    swapFromTokenAddress,
    swapToTokenAddress,
    setSwapFromCurrency,
    setSwapFromProtocol,
    setSwapFromBlockchain,
    setSwapFromTokenAddress,
    setSwapToCurrency,
    setSwapToProtocol,
    setSwapToBlockchain,
    setSwapToTokenAddress,
  } = useSwapContext();

  const [usedQuery, setUsedQuery] = useState(false);

  const multichainEnabled = isFeatureFlagEnabled('multichain');

  const isMultichain =
    multichainEnabled && exchangeProvider === 'bitcoincom_eth';

  // queries
  const queryFromToken = query.get('from')?.toUpperCase() || '';
  const queryToToken = query.get('to')?.toUpperCase() || '';
  const queryFromChain = query.get('fromchain')
    ? `${query.get('fromchain')?.toUpperCase()}_BLOCKCHAIN`
    : '';
  const queryToChain = query.get('tochain')
    ? `${query.get('tochain')?.toUpperCase()}_BLOCKCHAIN`
    : '';

  // token lists
  const multichainTokensIn = useMemo(() => {
    const fromChain = usedQuery ? swapFromBlockchain : queryFromChain;
    return multichainTokens
      ? multichainTokens[fromChain || swapFromBlockchain]
      : [];
  }, [multichainTokens, queryFromChain, swapFromBlockchain, usedQuery]);

  const dexTokensIn = useMemo(
    () => (isMultichain ? multichainTokensIn || [] : dexTokens),
    [isMultichain, multichainTokensIn, dexTokens[0]],
  );

  const multichainTokensOut = useMemo(() => {
    const toChain = usedQuery ? swapToBlockchain : queryToChain;
    return multichainTokens
      ? multichainTokens[toChain || swapToBlockchain]
      : [];
  }, [multichainTokens, queryToChain, swapToBlockchain, usedQuery]);

  const dexTokensOut = useMemo(
    () => (isMultichain ? multichainTokensOut || [] : dexTokens),
    [isMultichain, multichainTokensOut, dexTokens[0]],
  );

  // default tokens
  const defaultFromToken = useMemo(
    () => DEFAULT_SWAP_FROM[exchangeProvider],
    [exchangeProvider],
  );
  const defaultToToken = useMemo(
    () => DEFAULT_SWAP_TO[exchangeProvider],
    [exchangeProvider],
  );

  useEffect(() => {
    const fromTokenOverride = usedQuery ? '' : queryFromToken;
    const toTokenOverride = usedQuery ? '' : queryToToken;

    const selectedFromToken = dexTokensIn.find(t => {
      if (fromTokenOverride) {
        if (fromTokenOverride === 'VERSE') {
          return (
            t.abbr === fromTokenOverride &&
            Object.keys(VERSE_TOKENS).includes(t?.token)
          );
        }
        return t.abbr.toLowerCase() === fromTokenOverride.toLowerCase();
      }

      if (
        swapFromTokenAddress === defaultFromToken.token ||
        !swapFromTokenAddress
      ) {
        return t.abbr === swapFromCurrency;
      }

      return t.abbr === swapFromCurrency && t?.token === swapFromTokenAddress;
    });

    let selectedToToken = dexTokensOut.find(t => {
      if (toTokenOverride) {
        if (toTokenOverride === 'VERSE') {
          return (
            t.abbr === toTokenOverride &&
            Object.keys(VERSE_TOKENS).includes(t?.token)
          );
        }
        return t.abbr.toLowerCase() === toTokenOverride.toLowerCase();
      }
      if (!swapToTokenAddress) {
        return t.abbr === swapToCurrency;
      }

      return t.abbr === swapToCurrency && t?.token === swapToTokenAddress;
    });

    if (
      selectedFromToken !== undefined &&
      selectedToToken !== undefined &&
      selectedFromToken.abbr === selectedToToken.abbr &&
      selectedFromToken.token === selectedToToken.token &&
      selectedFromToken.blockchain === selectedToToken.blockchain
    ) {
      selectedToToken = dexTokensOut.find(
        t =>
          t.abbr !== selectedFromToken?.abbr &&
          t?.token !== selectedFromToken?.token &&
          t.blockchain !== selectedFromToken?.blockchain,
      );
    }

    if (queryFromToken || queryToToken) {
      setUsedQuery(true);
    }

    const fromBlockchain =
      selectedFromToken?.blockchain || defaultFromToken.blockchain;
    const toBlockchain =
      selectedToToken?.blockchain || defaultToToken.blockchain;

    // update context
    setSwapFromCurrency(selectedFromToken?.abbr || defaultFromToken.abbr);
    setSwapFromProtocol(
      selectedFromToken?.protocol || defaultFromToken.protocol,
    );
    setSwapFromBlockchain(
      fromBlockchain.includes('_BLOCKCHAIN')
        ? fromBlockchain
        : `${fromBlockchain}_BLOCKCHAIN`,
    );
    setSwapFromTokenAddress(
      selectedFromToken?.token || defaultFromToken.token || '',
    );
    setSwapToCurrency(selectedToToken?.abbr || defaultToToken.abbr);
    setSwapToProtocol(selectedToToken?.protocol || defaultToToken.protocol);
    setSwapToBlockchain(
      toBlockchain.includes('_BLOCKCHAIN')
        ? toBlockchain
        : `${toBlockchain}_BLOCKCHAIN`,
    );
    setSwapToTokenAddress(selectedToToken?.token || defaultToToken.token || '');
  }, [
    defaultFromToken.abbr,
    defaultToToken.abbr,
    dexTokensIn[0],
    dexTokensOut[0],
    exchangeProvider,
    multichainTokens,
    queryFromToken,
    queryToToken,
    swapFromBlockchain,
    swapToBlockchain,
    swapFromCurrency,
    swapToCurrency,
    swapFromTokenAddress,
    swapToTokenAddress,
    usedQuery,
  ]);

  return <></>;
};

export default DefiCurrencyHandler;
