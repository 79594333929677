import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { useCalls, useEthers } from '@usedapp/core';
import { BigNumber, Contract, utils } from 'ethers';

import { DEFAULT_DECIMALS, SECONDS_IN_YEAR } from '@context/constants';
import { useDexRates } from '@context/providers/DexRatesProvider';
import { useLiquidityPairs } from '@context/providers/LiquidityPairsProvider';
import { useTrackedState } from '@context/store';

import { ZERO_ADDRESS } from '@views/Swap/context/constants';

import { chunkArray } from '@helpers/array';
import { lpTokenValue } from '@helpers/lpTokenValue';
import { StakingInterface } from '@helpers/web3';

import { getFarmListForProvider } from '@views/Farms/helpers/getFarmListForProvider';
import { sortFarmsList } from '@views/Farms/helpers/sortFarmsList';
import { getStakingAPY } from '@views/Staking/helpers/getStakingAPY';

const fallbackFarm = getFarmListForProvider('bitcoincom_eth')[0];

type FarmContextState = {
  farmPairs: IFarmPair[];
  farmBalances: Record<string, BigNumber>;
  farmTotalSupply: Record<string, BigNumber>;
  farmEarned: Record<string, BigNumber>;
  selectedFarm: IFarmPair;
};

const DEFAULT_STATE: FarmContextState = {
  farmPairs: [],
  farmBalances: {},
  farmEarned: {},
  farmTotalSupply: {},
  selectedFarm: fallbackFarm,
};

const FarmsContext = createContext<FarmContextState>(DEFAULT_STATE);

type SetFarmPairsAction = { type: 'SET_FARMS'; payload: IFarmPair[] };
type SetFarmBalancesAction = {
  type: 'SET_BALANCES';
  payload: Record<string, BigNumber>;
};
type SetFarmTotalSupplyAction = {
  type: 'SET_TOTAL_SUPPLY';
  payload: Record<string, BigNumber>;
};
type SetFarmEarnedAction = {
  type: 'SET_EARNED';
  payload: Record<string, BigNumber>;
};
type SetSelectedFarmAction = {
  type: 'SET_SELECTED_FARM';
  payload: IFarmPair;
};

type Action =
  | SetFarmPairsAction
  | SetFarmBalancesAction
  | SetFarmEarnedAction
  | SetFarmTotalSupplyAction
  | SetSelectedFarmAction;

const reducer = (state: FarmContextState, action: Action): FarmContextState => {
  switch (action.type) {
    case 'SET_FARMS':
      return { ...state, farmPairs: action.payload };
    case 'SET_BALANCES':
      return { ...state, farmBalances: action.payload };
    case 'SET_EARNED':
      return { ...state, farmEarned: action.payload };
    case 'SET_SELECTED_FARM':
      return { ...state, selectedFarm: action.payload };
    case 'SET_TOTAL_SUPPLY':
      return { ...state, farmTotalSupply: action.payload };
    default:
      return state;
  }
};

export const FarmsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { provider, selectedFarmAddress } = useTrackedState();
  const { account, chainId } = useEthers();
  const liquidityPairs = useLiquidityPairs();
  const dexRates = useDexRates();

  const [
    { farmPairs, farmBalances, farmEarned, farmTotalSupply, selectedFarm },
    dispatch,
  ] = useReducer(reducer, DEFAULT_STATE);

  const rawList = useMemo(() => getFarmListForProvider(provider), [provider]);

  const callsResponse = useCalls(
    [
      ...rawList.map(farm => {
        return {
          contract: new Contract(farm.contractAddress, StakingInterface),
          method: 'rewardRate',
          args: [],
        };
      }),
      ...rawList.map(farm => {
        return {
          contract: new Contract(farm.contractAddress, StakingInterface),
          method: 'totalSupply',
          args: [],
        };
      }),
      ...rawList.map(farm => {
        return {
          contract: new Contract(farm.contractAddress, StakingInterface),
          method: 'balanceOf',
          args: [account || ZERO_ADDRESS],
        };
      }),
      ...rawList.map(farm => {
        return {
          contract: new Contract(farm.contractAddress, StakingInterface),
          method: 'earned',
          args: [account || ZERO_ADDRESS],
        };
      }),
      ...rawList.map(farm => {
        return {
          contract: new Contract(farm.contractAddress, StakingInterface),
          method: 'periodFinished',
          args: [],
        };
      }),
    ],
    { chainId },
  );

  useEffect(() => {
    if (
      callsResponse.length === 0 ||
      callsResponse.some(res => res?.value === undefined)
    ) {
      return;
    }

    const [rewardRates, totalSupplies, balances, earned, periodsFinished] =
      chunkArray(callsResponse, rawList.length);

    const appendedApyList = rawList.map((farm, index) => {
      const pair = liquidityPairs?.find(
        p => p.id.toLowerCase() === farm.pair.id.toLowerCase(),
      );

      const tokenSupply = parseFloat(
        utils.formatUnits(totalSupplies[index]?.value?.[0], DEFAULT_DECIMALS),
      );
      const rewardRate = parseFloat(
        utils.formatUnits(rewardRates[index]?.value?.[0], DEFAULT_DECIMALS),
      );

      if (farm.singleSided) {
        const stakingApy = getStakingAPY(
          farm,
          rewardRate,
          0,
          tokenSupply,
          dexRates,
          provider,
        );
        return { ...farm, apy: stakingApy };
      }

      if (
        !pair ||
        !totalSupplies?.[index]?.value ||
        !dexRates?.[provider]?.[farm.rewardToken.abbr.toUpperCase()]
      ) {
        return { ...farm, apy: '...' };
      }

      const totalSupplyFiat = lpTokenValue(tokenSupply, pair);

      const periodFinished = parseFloat(
        utils.formatUnits(periodsFinished[index]?.value?.[0], 0),
      );

      const totalYearlyRewards =
        Date.now() > periodFinished * 1000 ? 0 : rewardRate * SECONDS_IN_YEAR;

      const rewardTokenFiatRate =
        dexRates[provider][farm.rewardToken.abbr.toUpperCase()] || 0;

      const yearlyRewardsFiat = totalYearlyRewards * rewardTokenFiatRate;

      const estimatedAPY = (yearlyRewardsFiat / totalSupplyFiat) * 100;

      if (!tokenSupply) return { ...farm, apy: '...' };
      if (estimatedAPY > 500) return { ...farm, apy: '> 500' };

      return {
        ...farm,
        apy: estimatedAPY.toFixed(2),
      };
    });

    const sortedFarms = sortFarmsList(appendedApyList);

    const reducedBalances = rawList.reduce(
      (prev, curr, index) => ({
        ...prev,
        [curr.contractAddress]: balances[index]?.value[0] as BigNumber,
      }),
      {} as Record<string, BigNumber>,
    );

    dispatch({ type: 'SET_BALANCES', payload: reducedBalances });

    const reducedEarned = rawList.reduce(
      (prev, curr, index) => ({
        ...prev,
        [curr.contractAddress]: earned[index]?.value[0] as BigNumber,
      }),
      {} as Record<string, BigNumber>,
    );

    dispatch({ type: 'SET_EARNED', payload: reducedEarned });

    const reducedTotalSupply = rawList.reduce(
      (prev, curr, index) => ({
        ...prev,
        [curr.contractAddress]: totalSupplies[index]?.value[0] as BigNumber,
      }),
      {} as Record<string, BigNumber>,
    );

    dispatch({ type: 'SET_TOTAL_SUPPLY', payload: reducedTotalSupply });

    const activeFarms = sortedFarms.filter(
      farm => !(reducedBalances[farm.contractAddress] && farm.hidden),
    );

    dispatch({ type: 'SET_FARMS', payload: activeFarms });
  }, [callsResponse, rawList, liquidityPairs, dexRates]);

  useEffect(() => {
    dispatch({
      type: 'SET_SELECTED_FARM',
      payload:
        farmPairs.find(
          f =>
            f.contractAddress.toLowerCase() ===
            selectedFarmAddress.toLowerCase(),
        ) ||
        rawList.find(
          f =>
            f.contractAddress.toLowerCase() ===
            selectedFarmAddress.toLowerCase(),
        ) ||
        fallbackFarm,
    });
  }, [selectedFarmAddress, farmPairs]);

  const value = useMemo(
    () => ({
      farmPairs,
      farmBalances,
      farmEarned,
      farmTotalSupply,
      selectedFarm,
    }),
    [farmPairs, farmBalances, farmEarned, farmTotalSupply, selectedFarm],
  );

  return (
    <FarmsContext.Provider value={value}>{children}</FarmsContext.Provider>
  );
};

export const useFarms = () => useContext(FarmsContext);

export default FarmsProvider;
