import { GraphQLClient, gql } from 'graphql-request';

import { getBlockHeight } from '@context/api/nekosdk';

type Volume = {
  id: string;
  volumeUSD: string;
};

type AllLiquidityPairsResponse = {
  volumeYesterday: Volume[];
  pairs: ILiquidityPair[];
};

type NativeTokenAttributes = {
  symbol: string;
  name: string;
  icon?: string;
};

type NativeTokenInfoType = Record<string, NativeTokenAttributes>;

const NATIVE_TOKEN_INFO: NativeTokenInfoType = {
  WBCH: {
    symbol: 'BCH',
    name: 'Smart BCH',
    icon: 'https://raw.githubusercontent.com/bitcoin-portal/cryptocurrency-icons/master/32%402x/icon/bch%402x.png',
  },
  WETH: {
    symbol: 'ETH',
    name: 'Ethereum',
    icon: 'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/128/Ethereum-ETH-icon.png',
  },
};

const nativeTokenAttributes = (symbol: string): NativeTokenAttributes => {
  return NATIVE_TOKEN_INFO[symbol] || {};
};

export const getAllLiquidityPairs = async (
  url: string,
): Promise<ILiquidityPair[] | undefined> => {
  try {
    const yesterdayStart = new Date();
    yesterdayStart.setUTCHours(0, 0, 0, 0);
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    const unixStart = Math.ceil(yesterdayStart.getTime() / 1000);

    const blockResp = await getBlockHeight(unixStart);
    const { height: previousDayBlock } = blockResp.getValue();

    const bcomClient = new GraphQLClient(url, {
      headers: {},
    });

    const lpQuery = gql`
      query allLiquidityPairs {
        volumeYesterday: pairs(
          orderBy: reserveUSD
          orderDirection: desc
          block: { number: ${previousDayBlock} }
        ) {
          id
          volumeUSD
        }
        pairs: pairs(orderBy: reserveUSD, orderDirection: desc) {
          id
          name
          reserveUSD
          totalSupply
          volumeUSD
          token0 {
            name
            symbol
            id
            decimals
            derivedETH
          }
          token1 {
            name
            symbol
            id
            decimals
            derivedETH
          }
          token0Price
          token1Price
        }
      }
    `;

    const res = await bcomClient.request<AllLiquidityPairsResponse>(lpQuery);

    const reduced = res.pairs.map(pair => {
      const { reserveUSD, volumeUSD: volumeToNow } = pair;

      const { volumeUSD: volumeToYesterday } = res.volumeYesterday.find(
        i => i.id === pair.id,
      ) || { volumeUSD: volumeToNow };

      const volumeDay = Number(volumeToNow) - Number(volumeToYesterday);

      const estimatedApy =
        ((volumeDay * 0.0025 * 365) / Number(reserveUSD)) * 100;

      return {
        ...pair,
        token0: {
          ...pair.token0,
          ...nativeTokenAttributes(pair.token0.symbol),
        },
        token1: {
          ...pair.token1,
          ...nativeTokenAttributes(pair.token1.symbol),
        },
        apy: estimatedApy,
      };
    });

    return reduced;
  } catch (error) {
    console.log('Error getting all liquidity pairs', error);
    return undefined;
  }
};
