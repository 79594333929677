import styled, { css } from 'styled-components';

import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

import Image from '../../Image';
import { media } from '../media';

export const HeaderSection = styled.div<{
  hasBottomBorder?: boolean;
  offsetTop?: boolean;
}>`
  grid-area: header;
  position: fixed;
  width: 100%;
  top: ${props => (props.offsetTop ? '36px' : 0)};
  left: 0;
  z-index: 3;
  background-color: ${colors.shade20};
  border-bottom: 1px solid ${colors.shade40};
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  ${media.portrait} {
    ${({ hasBottomBorder }) =>
      !hasBottomBorder &&
      css`
        border: none;
      `}
    background-color: ${colors.shade10};
    justify-content: flex-end;
  }
`;

export const StyledHeaderIcon = styled(Image)`
  margin-right: 10px;
  @media (min-width: ${media.small}) {
    display: none;
  }
`;

export const StyledHeaderLogo = styled(Image)`
  display: none;
  @media (min-width: ${media.small}) {
    display: block;
    margin-right: 5px;
  }
  ${media.portrait} {
    display: none;
  }
`;

export const HeaderBtnWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
