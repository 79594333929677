import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const StakingIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="staking" viewBox="0 0 20 20" fill="none" {...rest}>
        <path
          d="M0.634359 13L0.531249 13.0605C-0.177082 13.4759 -0.177084 14.5241 0.531248 14.9395L8.43063 19.5723C9.40306 20.1426 10.5968 20.1426 11.5693 19.5723L19.4687 14.9395C20.1771 14.5241 20.1771 13.4759 19.4687 13.0605L19.3656 13L11.5693 17.5723C10.5968 18.1426 9.40306 18.1426 8.43063 17.5723L0.634359 13Z"
          fill="currentColor"
        />
        <path
          d="M0.634359 9L0.531249 9.06047C-0.177082 9.47589 -0.177084 10.5241 0.531248 10.9395L8.43063 15.5723C9.40306 16.1426 10.5968 16.1426 11.5693 15.5723L19.4687 10.9395C20.1771 10.5241 20.1771 9.47588 19.4687 9.06047L19.3656 9L11.5693 13.5723C10.5968 14.1426 9.40306 14.1426 8.43063 13.5723L0.634359 9Z"
          fill="currentColor"
        />
        <path
          d="M8.43063 0.427728C9.40306 -0.142574 10.5968 -0.142576 11.5693 0.427721L19.4687 5.06047C20.1771 5.47588 20.1771 6.52412 19.4687 6.93953L11.5693 11.5723C10.5968 12.1426 9.40306 12.1426 8.43063 11.5723L0.531248 6.93953C-0.177084 6.52412 -0.177082 5.47589 0.531249 5.06047L8.43063 0.427728Z"
          fill="currentColor"
        />
      </SvgIcon>
    </>
  );
};

export default StakingIcon;
