// finds correct farm from pair param
export const getFarmForPath = (
  pairString: string,
  farmList: IFarmPair[],
): IFarmPair | undefined => {
  const [tokenA, tokenB, oldFarm] = pairString.split('-');

  const foundPair = farmList.find(farm => {
    const s1 = tokenA.toUpperCase();
    const s2 = tokenB.toUpperCase();

    const token0 = farm.pair.token0.symbol.toUpperCase();
    const token1 = farm.pair.token1.symbol.toUpperCase();

    if (oldFarm && oldFarm === 'old') {
      return (
        farm.hidden &&
        ((token0 === s1 && token1 === s2) || (token0 === s2 && token1 === s1))
      );
    }

    return (token0 === s1 && token1 === s2) || (token0 === s2 && token1 === s1);
  });

  return foundPair;
};
