import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const AnalyticsIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="analytics" viewBox="0 0 20 20" fill="none" {...rest}>
        <path d="M11.3333 0H14.3333V20H11.3333V0Z" fill="#899BB5" />
        <path d="M8.66667 4H5.66667V20H8.66667V4Z" fill="#899BB5" />
        <path d="M3 10H0V20H3V10Z" fill="#899BB5" />
        <path d="M20 6H17V20H20V6Z" fill="#899BB5" />
      </SvgIcon>
    </>
  );
};

export default AnalyticsIcon;
