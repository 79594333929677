import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { LiFi } from '@lifi/sdk';

import { ACTIVE_ENV, LIFI_KEY } from '@context/constants';

import { LIFI_FEE, LIFI_INTEGRATOR_IDS } from '@views/Swap/context/constants';

export const LiFiContext = createContext<LiFi | null>(null);

export const LiFiProvider: FC<PropsWithChildren> = ({ children }) => {
  const [lifi, setLifi] = useState<LiFi | null>(null);

  useEffect(() => {
    const initializeLiFi = async () => {
      const lifiInstance = new LiFi({
        integrator: LIFI_INTEGRATOR_IDS[ACTIVE_ENV],
        apiKey: LIFI_KEY,
        defaultRouteOptions: {
          fee: LIFI_FEE,
          maxPriceImpact: 0.4,
        },
      });

      setLifi(lifiInstance);
    };

    if (!lifi) {
      initializeLiFi();
    }
  }, []);

  return <LiFiContext.Provider value={lifi}>{children}</LiFiContext.Provider>;
};

export const useLiFi = (): LiFi | null => {
  const providerRef = useContext(LiFiContext);

  return providerRef;
};
