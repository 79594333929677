import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { LIFI_CHAINS } from '@context/constants';

import {
  LIFI_UNLISTED_TOKENS,
  VERSE_TOKENS,
} from '@views/Swap/context/constants';
import { useLiFi } from '@views/Swap/context/providers/LiFiProvider';

import { filterMultichainTokens } from '@views/Swap/helpers/filterMultichainTokens';
import { getLifiChainById } from '@views/Swap/helpers/getLifiChainById';

type TMultichainTokensContext = {
  [chainName: string]: IMultichainToken[];
};

const MultichainTokensContext = createContext<TMultichainTokensContext>({
  ETH_BLOCKCHAIN: [],
  AVAX_BLOCKCHAIN: [],
  MATIC_BLOCKCHAIN: [],
  BNB_BLOCKCHAIN: [],
});

const MultichainTokensProvider: FC<PropsWithChildren> = ({ children }) => {
  const liFi = useLiFi();

  const [multichainTokens, setMultichainTokens] = useState<{
    [chainName: string]: IMultichainToken[];
  }>({
    ETH_BLOCKCHAIN: [],
    AVAX_BLOCKCHAIN: [],
    MATIC_BLOCKCHAIN: [],
    BNB_BLOCKCHAIN: [],
  });

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const chainIds = Object.values(LIFI_CHAINS).map(chain => chain.chainId);

        const resp = await liFi?.getTokens({
          chains: chainIds,
        });

        if (resp) {
          const liFiTokens = Object.keys(resp.tokens).reduce((acc, chainId) => {
            const chainTokens = resp.tokens[chainId as unknown as number];

            const verseToken = chainTokens?.find(t =>
              Object.values(VERSE_TOKENS).includes(t.address.toLowerCase()),
            );

            if (verseToken) {
              const verseIdx = chainTokens.indexOf(verseToken);
              chainTokens.splice(verseIdx, 1);
              chainTokens.unshift(verseToken);
            }

            const chainName = getLifiChainById(Number(chainId));

            const chainInfo = LIFI_CHAINS[chainName];

            const filteredChainTokens = filterMultichainTokens(chainTokens);

            LIFI_UNLISTED_TOKENS?.[chainId]?.forEach(token => {
              if (
                filteredChainTokens.some(
                  t => t.address.toLowerCase() === token.address.toLowerCase(),
                )
              )
                return;

              filteredChainTokens.push(token);
            });

            const tokens = filteredChainTokens.map(token => {
              return {
                abbr: token.symbol,
                value: token.symbol,
                blockchain: chainName.split('_')[0],
                label: token.name,
                ticker: token.symbol.toLowerCase(),
                explorer: chainInfo.explorer,
                protocol:
                  token.symbol === chainInfo.nativeCurrency
                    ? chainInfo.nativeProtocol
                    : chainInfo.tokenProtocol,
                token: token.address,
                icon: token.logoURI,
                decimals: token.decimals,
                priceUSD: token.priceUSD,
              };
            });

            return {
              ...acc,
              [chainName]: tokens,
            };
          }, {});

          setMultichainTokens(liFiTokens);
        }
      } catch (e) {
        console.log('Error fetching LiFi tokens', e);
      }
    };

    if (liFi) fetchTokens();
  }, [liFi]);

  return (
    <MultichainTokensContext.Provider value={multichainTokens}>
      {children}
    </MultichainTokensContext.Provider>
  );
};

export const useMultichainTokens = () => useContext(MultichainTokensContext);

export default MultichainTokensProvider;
