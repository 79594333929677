import styled, { createGlobalStyle } from 'styled-components';

import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  ___gatsby,
  ___gatsby > div {
    font-family: 'Barlow', -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
      sans-serif;
    color: #FAFAFA;
    background: ${colors.shade10};
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
  }

  .osano-cm-window {
    display: none;
  }

  button.osano-cm-widget {
    display: none;
  }

  #walletconnect-wrapper {
    .walletconnect-modal__base {
      color: #333;
    }
  }
`;

export const StyledSection = styled.section`
  position: relative;
  min-height: 100vh;
  padding: 0;
  background-color: ${colors.shade10};
  width: 100%;

  box-sizing: border-box;
  overflow: hidden;
`;
