import React, { FC, PropsWithChildren } from 'react';

import {
  Avalanche,
  BSC,
  Chain,
  ChainId,
  Config,
  Mainnet,
  MetamaskConnector,
  Polygon,
  Sepolia,
  DAppProvider as UseDAppProvider,
} from '@usedapp/core';
import { EthereumProviderOptions } from '@walletconnect/ethereum-provider';
import type { QrModalOptions } from '@walletconnect/ethereum-provider/dist/types/types';

import { WalletConnectConnector } from '../connectors/WalletConnectConnector';
import { WalletConnectUniversal } from '../connectors/WalletConnectUniversalConnector';
import {
  BASE_URL,
  RPC_URL_AVALANCHE,
  RPC_URL_BINANCE,
  RPC_URL_ETHEREUM,
  RPC_URL_HOLESKY,
  RPC_URL_POLYGON,
  RPC_URL_SEPOLIA,
  RPC_URL_SMARTBCH,
  WALLETCONNECT_PROJECT_ID,
} from '../constants';

// name and native currency matched from here - https://github.com/smartbch/atomic-swap-frontend/blob/main/src/common/web3.ts
const SmartBCH: Chain = {
  chainId: 10000,
  chainName: 'smartBCH',
  nativeCurrency: {
    symbol: 'bch',
    name: 'BCH',
    decimals: 18,
  },
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xDdFB2A64E211BCdaeDaAB4f886A03EdE102CdD59',
  getExplorerAddressLink: (address: string) =>
    `https://www.smartscout.cash/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://www.smartscout.cash/tx/${transactionHash}`,
  rpcUrl: RPC_URL_SMARTBCH,
  blockExplorerUrl: 'https://www.smartscout.cash/',
};

const Holesky: Chain = {
  chainId: 17000,
  chainName: 'Holesky',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
  multicall2Address: '0xcA11bde05977b3631167028862bE2a173976CA11',
  rpcUrl: 'https://1rpc.io/holesky',
  blockExplorerUrl: 'https://sepolia.etherscan.io',
  nativeCurrency: {
    name: 'Holesky Ether',
    symbol: 'HoleskyETH',
    decimals: 18,
  },
  getExplorerAddressLink: (address: string) =>
    `https://holesky.etherscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://holesky.etherscan.io/tx/${transactionHash}`,
};

export const rpcMap = {
  [ChainId.Mainnet]: RPC_URL_ETHEREUM,
  [ChainId.Sepolia]: RPC_URL_SEPOLIA,
  [Holesky.chainId]: RPC_URL_HOLESKY,
  [SmartBCH.chainId]: RPC_URL_SMARTBCH,
  [ChainId.Avalanche]: RPC_URL_AVALANCHE,
  [ChainId.BSC]: RPC_URL_BINANCE,
  [ChainId.Polygon]: RPC_URL_POLYGON,
};

const baseWcConfig: EthereumProviderOptions = {
  projectId: WALLETCONNECT_PROJECT_ID,
  rpcMap,
  showQrModal: false,
  chains: [ChainId.Mainnet],
  optionalChains: [ChainId.Avalanche, ChainId.Polygon, ChainId.BSC],
  methods: ['eth_sendTransaction', 'personal_sign'],
  events: ['chainChanged', 'accountsChanged'],
};

const baseQrModalOptions: QrModalOptions = {
  themeMode: 'dark',
  themeVariables: {
    '--w3m-font-family': 'Barlow, sans-serif',
    '--w3m-logo-image-url':
      'https://verse.bitcoin.com/images/uploads/versev3-logo.png',
    '--w3m-background-color': '#0F1823',
  },
};

const metadata = {
  name: 'Verse DEX',
  description:
    'Home of Bitcoin.com’s rewards and utility token VERSE. Access deep liquidity, earn 75% of fees in LPs, get ~80% APY on Farms. Available on Ethereum and SmartBCH.',
  url: 'https://verse.bitcoin.com',
  icons: [`${BASE_URL}images/favicon.png`],
};

const config: Config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: rpcMap,
  // TODO: Change to V2 when discontinuing smartBCH
  multicallVersion: 1 as const,
  networks: [Mainnet, Sepolia, Holesky, Polygon, Avalanche, BSC, SmartBCH],
  fastMulticallEncoding: true,
  autoConnect: true,
  connectors: {
    metamask: new MetamaskConnector(),
    walletconnect: new WalletConnectConnector({
      ...baseWcConfig,
      qrModalOptions: {
        ...baseQrModalOptions,
        explorerRecommendedWalletIds: [
          '107bb20463699c4e614d3a2fb7b961e66f48774cb8f6d6c1aee789853280972c',
          '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
          '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
          'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        ],
      },
      metadata,
    }),
    bcomwallet: new WalletConnectUniversal({
      ...baseWcConfig,
      metadata,
    }),
    wcuniversal: new WalletConnectUniversal({
      ...baseWcConfig,
      metadata,
    }),
  },
};

const DAppProvider: FC<PropsWithChildren> = ({ children }) => {
  return <UseDAppProvider config={config}>{children}</UseDAppProvider>;
};

export default DAppProvider;
