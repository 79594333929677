import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const VoteIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="vote" viewBox="0 0 20 20" fill="none" {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9999 0C14.9427 0 15.4142 0 15.707 0.292893C15.9999 0.585786 15.9999 1.05719 15.9999 2L15.9999 14L3.99994 14V2C3.99994 1.05719 3.99994 0.585786 4.29283 0.292893C4.58573 0 5.05713 0 5.99994 0H13.9999ZM13.8167 3.83331C14.1849 4.14628 14.2297 4.69849 13.9167 5.0667L9.66663 10.0667C9.50838 10.2529 9.27948 10.3644 9.03532 10.3743C8.79117 10.3842 8.554 10.2915 8.38122 10.1187L6.38122 8.11872C6.03951 7.77701 6.03951 7.22299 6.38122 6.88128C6.72293 6.53957 7.27695 6.53957 7.61866 6.88128L8.94775 8.21037L12.5833 3.9333C12.8963 3.5651 13.4485 3.52033 13.8167 3.83331Z"
          fill="#899BB5"
        />
        <path
          d="M1.99994 13H-6.10352e-05V20H19.9999V13H17.9999V16H1.99994V13Z"
          fill="#899BB5"
        />
      </SvgIcon>
    </>
  );
};

export default VoteIcon;
