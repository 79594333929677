import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const PoolsIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="pools" viewBox="0 0 21 20" fill="none" {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.59375 3.78125C4.59375 2.79749 5.39124 2 6.375 2C7.35876 2 8.15625 2.79749 8.15625 3.78125V4H10.1562V3.78125C10.1562 1.69292 8.46333 0 6.375 0C4.28667 0 2.59375 1.69292 2.59375 3.78125V12.6002C2.06397 12.6238 1.53835 12.7678 1.05923 13.0322L0 13.6166V14.4375V15.9689L1.30143 15.2509C2.20386 14.753 3.29754 14.7537 4.19923 15.2512L5.89192 16.1851C6.19261 16.351 6.55739 16.351 6.85808 16.1851L8.55077 15.2512C9.45283 14.7535 10.5472 14.7535 11.4492 15.2512L13.1419 16.1851C13.4426 16.351 13.8074 16.351 14.1081 16.1851L15.8008 15.2512C16.7028 14.7535 17.7972 14.7535 18.6992 15.2512L20 15.9689L20 14.4375L20 13.6166L18.9408 13.0322C17.8884 12.4516 16.6116 12.4516 15.5592 13.0322L14.3438 13.7028V3.78125C14.3438 2.79749 15.1412 2 16.125 2C17.1088 2 17.9062 2.79749 17.9062 3.78125V4H19.9062V3.78125C19.9062 1.69292 18.2133 0 16.125 0C14.0367 0 12.3438 1.69292 12.3438 3.78125V7.56018H4.59375V3.78125ZM20 18.2531L17.7331 17.0024C17.4324 16.8365 17.0676 16.8365 16.7669 17.0024L15.0742 17.9363C14.1722 18.434 13.0778 18.434 12.1758 17.9363L10.4831 17.0024C10.1824 16.8365 9.81761 16.8365 9.51692 17.0024L7.82423 17.9363C6.92217 18.434 5.82783 18.434 4.92577 17.9363L3.23308 17.0024C2.93202 16.8363 2.56791 16.8363 2.26759 17.002L0 18.2531V20H20L20 18.2531ZM11.6908 13.0322L12.3438 13.3925V9.56018H4.59375V13.1166L6.13346 13.9661C6.28381 14.049 6.4662 14.049 6.61654 13.9661L8.30923 13.0322C9.36164 12.4516 10.6384 12.4516 11.6908 13.0322Z"
          fill="currentColor"
        />
      </SvgIcon>
    </>
  );
};

export default PoolsIcon;
