import React, { FunctionComponent, ReactNode } from 'react';

import SvgIcon from '@bitcoin-portal/verse-web-components/dist/Icons/SvgIcon';

const SwapIcon: FunctionComponent<{
  [any: string]: ReactNode;
}> = ({ ...rest }) => {
  return (
    <>
      <SvgIcon data-icon="swap" viewBox="0 0 20 20" fill="none" {...rest}>
        <path
          d="M2 8.75001H20L10.9504 0V6.18751H2V8.75001Z"
          fill="currentColor"
        />
        <path
          d="M18 11.186H0L9.07785 20V13.75H18V11.186Z"
          fill="currentColor"
        />
      </SvgIcon>
    </>
  );
};

export default SwapIcon;
